<template>
    <div class="app flex-row align-items-center">
      <div class="container">
        <h2>{{ $t('searchTermStatisticList') }}</h2>
        <grid-view-table
          id="statistic-search"
          :columns="columns"
          :path="'statistic-search'"
          :per-page="100"
        />
      </div>
    </div>
  </template>
  
  <script>
    import GridViewTable from '../../components/GridViewTable';
    import { columns } from './grid-views/search-terms';
  
    export default {
      name: 'SearchTermList',
      components: {
        GridViewTable,
      },
      data: () => {
        return {
          columns,
        }
      }
    }
  </script>