export const columns = [
  {
    label: 'searchTerm',
    field: 'term',
    width: '70%',
    sortable: false,
    filterOptions: {
      enabled: false,
    },
  },
  {
    label: 'count',
    field: 'count',
    width: '30%',
    sortable: false,
    filterOptions: {
      enabled: false,
    },
  },
]
